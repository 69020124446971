@media (min-width: 1600px) and (max-width: 2160px) {
  .right__card {
    .circle {
      margin-top: 20px !important;
      width: 160px !important;
    }

    //.progress__title {
    //  font-size: 18px !important;
    //}
  }

  .info__card {
    font-size: 15px !important;
    padding: 15px !important;

    .p {
      font-size: 18px !important;
    }

    .info__numbers {
      margin: 0 auto 10px !important;
      width: 55px !important;
      height: 55px !important;
      line-height: 55px !important;
      font-size: 17px !important;
    }

    .info {
      line-height: 22px !important;
      // padding-left: 12px !important;
    }

    .info0 {
      line-height: 22px !important;
    }

    .info1 {
      line-height: 20px !important;
    }
  }

  //        .circle{
  //     margin-top: 20px;
  //     width:150px;
  //   }
  //  .progress__title {
  //      text-align: center;
  //      margin-top: 20px;
  //      font-size: 15px;
  //     }

  .body__card {
    .table {
      tbody {
        tr {
          td {
            //font-size: 15px !important;

            .progress {
              height: 12px !important;

              &__value {
                width: 42px !important;
                height: 42px !important;
                line-height: 42px !important;
                font-size: 15px !important;
              }
            }
          }
        }
      }
    }
  }
}

.home {
  width: 100%;

  &__info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .info__card {
      padding: 15px;
      width: 24%;
      color: #fff;
      background: #6a69c7;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      font-weight: 600;
      font-size: 13px;

      .p {
        font-weight: 500;
        color: #fff;
      }

      span {
        font-size: 10px;
        padding: 5px !important;
        font-weight: 600;
      }

      @media (min-width: 1440px) {
        span,
        p {
          font-size: 15px;
        }
      }
      @media (min-width: 1900px) {
        span,
        p {
          font-size: 16px;
        }
      }

      .info__numbers {
        margin: 0 auto 10px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        background-color: #fff;
        color: #6a69c7;
        font-weight: 600;
        font-size: 14px;
      }

      &:last-of-type {
        background-color: #457b9d;

        .info__numbers {
          color: #457b9d;
        }

        .info {
          color: #457b9d;
        }
      }

      &:nth-last-of-type(2) {
        background-color: #ff808b;

        .info__numbers {
          color: #ff808b;
        }

        .info {
          color: #ff808b;
        }
      }

      &:nth-last-of-type(3) {
        background-color: #ffaf6f;

        .info__numbers {
          color: #ffaf6f;
        }

        .info {
          color: #ffaf6f;
        }
      }

      .info {
        font-weight: 500;
        padding: 2px 5px;
        background-color: #fff;
        color: #6a69c7;
        line-height: 17px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(8, 230, 218, 0.05);
        animation: mymove 0.6s infinite alternate;
        @keyframes mymove {
          from {
            transform: scale(1);
            opacity: 0.5;
          }
          to {
            transform: scale(1.1);
            opacity: 1;
          }
        }
      }

      .info0 {
        font-weight: 500;
        padding: 2px 5px;
        line-height: 17px;
        background-color: #fff;
        color: #6a69c7;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(8, 230, 218, 0.05);
        animation: mymove2 1s infinite alternate;
        @keyframes mymove2 {
          from {
            opacity: 0.4;
          }
          to {
            opacity: 1;
          }
        }
      }

      .info1 {
        font-weight: 500;
        padding: 2px 5px;
        line-height: 17px;
        background-color: #fff;
        color: #6a69c7;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(8, 230, 218, 0.05);
      }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .body__card {
      width: 74.6%;
      background-color: #fff;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 15px;

      .more__btn {
        color: #29a0e3;
        padding: 5px 10px;
        background: rgba(41, 160, 227, 0.1);
        border-radius: 8px;
      }

      .table {
        tbody {
          tr {
            td {
              border: none;

              .bg__light {
                color: #333333;
                background-color: rgba(51, 51, 51, 0.1);
              }

              .progress {
                width: 100%;
                height: 10px;
                position: relative;
                background-color: transparent;
                border-radius: 8px 0 0 8px;

                &__bar {
                  background: #07a287;
                  border-radius: 20px 0 0 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 80%;
                  width: 0;
                  transition: 1s ease 0.3s;
                  position: relative;
                }

                &__value {
                  text-align: center;
                  width: 34px;
                  height: 34px;
                  border-radius: 50%;
                  line-height: 34px;
                  background: #07a287;
                  font-size: 11px;
                  font-weight: 600;
                  color: #fff;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: -30px;
                }
              }
            }
          }
        }
      }
    }

    .right__card {
      .circle {
        margin-top: 15px;
        width: 120px;
        font-size: 15px !important;
        font-weight: 600;
      }

      .progress__title {
        text-align: center;
        margin-top: 10px;
        //font-size: 15px !important;
        font-weight: 600;
      }

      width: 24%;
      background-color: #fff;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 15px;
    }

    .card__title {
      color: #07a287;
      font-weight: 500;
    }

    .pie__chart {
      width: 150px;
      height: 120px;
      border-radius: 50%;
      margin: 0 auto;
      border: 10px solid #ebebeb;
    }
  }
}

.home__table__header {
  font-size: 16px;
}

.home__table__body {
  td {
    font-size: 16px;
  }
}


@media (min-width: 1440px) {
  .home__table__header {
    font-size: 17px !important;
  }
  .home__table__body {
    td {
      font-size: 16px !important;
    }
  }
}

@media (min-width: 1660px) {
  .home__table__header {
    font-size: 19px !important;
  }
  .home__table__body {
    td {
      font-size: 18px !important;
    }
  }
}

@media (min-width: 1900px) {
  .home__table__header {
    font-size: 20px !important;
  }
  .home__table__body {
    td {
      font-size: 19px !important;
    }
  }
}