//@media (min-width: 1400px) and (max-width: 1600px) {
//  .nav-link {
//    font-size: 15px !important;
//  }
//}
//
//@media (min-width: 1601px) and (max-width: 2100px) {
//  .nav-link {
//    font-size: 18px !important;
//  }
//}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'IBM Plex Sans', sans-serif;
}

body {
	margin: 0;
	min-height: 100vh;
	font-family: 'IBM Plex Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.isOpen {
	width: calc(100% - 95px);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;

	&__active {
		width: calc(100% - 300px);
	}
}

::-webkit-scrollbar {
	background-color: #f8f9fa;
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #07a287;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	cursor: pointer;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none !important;
	}
}

button {
	border: 0;
	white-space: nowrap;
	font-weight: 500;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.nav-link {
	width: 50% !important;
}

//input types
textarea.form-control {
	resize: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	background: white !important;
	color: #07a287 !important;
	border: none !important;
	font-weight: 600;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
	background-color: #ebebed;
	color: #000;
	font-size: 14px;
	margin: 0;
	padding: 14px 0;
	text-align: center;
	font-weight: 600;
}

.modal-header {
	border-bottom: none !important;
}

.progress {
	width: 170px !important;
	height: 6px !important;
}

//upload files

.file-upload-container input[type='file'] {
	display: none;
}

.file-upload-container button {
	width: 100%;
	height: 180px;
}

.file-upload-container .upload-preview {
	list-style-type: none;
	margin-bottom: 0;
}

.file-upload-container li {
	display: flex;
	align-items: center;
	margin: 10px 0;
}

.file-upload-container img {
	width: 50px;
	height: 50px;
}

.file-upload-container button:nth-child(2) {
	background: #fafbff;
	border: 3px dashed #cddbff;
	box-sizing: border-box;
	border-radius: 8px;
}

.upload-btn {
	margin: 5px 0;
	padding: 4px 10px;
	background-color: #29a0e3;
	color: #fff;
	float: right;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

@media all {
	.page-break {
		display: none;
	}
}

@media all {
	.page-break {
		display: none;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
	}
}

@media print {
	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-before: auto;
	}
}

@page {
	size: auto;
	margin: 20mm;
}

@media (min-width: 1440px) {
	h4 {
		font-size: 24px;
	}

	h5 {
		font-size: 22px !important;
	}

	h6 {
		font-size: 20px !important;
	}

	button,
	a,
	input,
	label {
		font-size: 18px !important;
		span {
			line-height: 26px !important;
		}
	}
	td {
		font-size: 16px !important;
		a {
			font-size: 16px !important;
		}
	}

	.modal-lg {
		max-width: 1000px !important;
	}

	.circle__home {
		width: 50px !important;
		height: 50px !important;
		font-size: 18px !important;
	}
}

@media (min-width: 1660px) {
	button,
	a,
	input,
	label {
		font-size: 20px !important;
		span {
			line-height: 28px !important;
		}
	}
	td {
		font-size: 18px !important;
		a,
		p {
			font-size: 18px !important;
		}
	}

	.circle__home {
		width: 55px !important;
		height: 55px !important;
		font-size: 19px !important;
	}

	.circle__table {
		width: 40px !important;
		height: 40px !important;
		font-size: 16px !important;
	}
}

@media (min-width: 1900px) {
	.circle__home {
		width: 60px !important;
		height: 60px !important;
		font-size: 20px !important;
	}
	.home__table__header {
		font-size: 22px !important;
	}
}
