.navbar {
  &__main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 300px;
    height: 100vh;
    min-height: 100vh;
    overflow-y: scroll;
    background-color: #07a287;

    h5 {
      font-size: 18px;
    }

    span {
      font-weight: 500;

      @media (min-width: 1900px) {
        font-size: 19px;
      }
    }

    .nav {
      position: relative;
      padding: 0 20px;

      .navbar__head {
        padding: 20px 0 10px;
        text-align: center;

        .navbar__logo {
          img {
            width: 55px;
          }
        }
      }

      .navbar__toggler {
        position: fixed;
        top: 132px;
        left: 282px;
        z-index: 11;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background-color: #fff;
        cursor: pointer;
        border: 2px solid #07a287;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        &__icon {
          transform: rotateZ(-90deg);
          -webkit-transform: rotateZ(-90deg);
          -moz-transform: rotateZ(-90deg);
          -ms-transform: rotateZ(-90deg);
          -o-transform: rotateZ(-90deg);
          transition: all 0.4s ease;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          -ms-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;

          &__act {
            transform: rotateZ(90deg);
            -webkit-transform: rotateZ(90deg);
            -moz-transform: rotateZ(90deg);
            -ms-transform: rotateZ(90deg);
            -o-transform: rotateZ(90deg);
          }
        }
      }

      .links {
        position: absolute;
        top: 180px;
        left: 15px;
        width: calc(100% - 30px);
        padding-left: 0;
        list-style: none;

        &__title {
          padding: 10px 0;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.75);

          &:first-child {
            padding: 0;
          }
        }

        .list_link {
          width: 100% !important;
          margin: 10px 0;
          text-decoration: none;
          color: #fff;

          &:hover {
            text-decoration: none;
          }

          &:focus {
            box-shadow: none;
          }
        }

        .accordion__icon {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;

          &__active {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
          }
        }

        .link {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 5px;
          padding: 12px 15px;
          font-size: 17px;
          color: #fff;
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          -ms-border-radius: 6px;
          -o-border-radius: 6px;
          transition: all 0.2s ease;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;

          li {
            display: flex;
            align-items: center;
            width: 100%;
          }

          &__active {
            background-color: #008e76;
          }

          &:hover {
            opacity: 0.8;
          }

          .tooltip {
            position: fixed;
            z-index: 99999;
            margin-left: 30px;
            padding: 5px;
            text-align: center;
            font-size: 14px;
            background-color: #007d69;
            color: #fff;
            visibility: hidden;
            border-radius: 6px;

            &:after {
              content: " ";
              position: absolute;
              top: 50%;
              left: -5px;
              width: 10px;
              height: 10px;
              border-right: 5px solid #007d69;
              border-top: 5px solid #007d69;
              transform: translateY(-50%) rotate(225deg);
            }
          }

          &:hover .tooltip {
            visibility: visible;
          }
        }
      }
    }
  }

  .badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-left: 6px;
    font-size: 10px;
    background-color: red;
    color: #fff;
    border-radius: 50%;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }

  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

.accordion__icon {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;

  &__active {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}
