.uploadImage {
  .content {
    position: relative;

    .upload__btn {
      width: 100%;
      padding: 35px;
      background: #fafbff;
      border: 3px dashed #cddbff;
      box-sizing: border-box;
      border-radius: 8px;

      p {
        margin: 0;
        color: #29a0e3;
      }
    }

    .delete__btn {
      position: absolute;
      color: #ff5252;
      top: 1px;
      right: 6px;
      background-color: transparent;
    }

    .image {
      position: absolute;
      top: 3px;
      left: 2.5px;
      height: 171px;
      display: flex;
      align-items: center;
      border-radius: 7px;
      overflow: hidden;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      -ms-border-radius: 7px;
      -o-border-radius: 7px;

      &__main {
        width: 220px;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
      }

      .action__btns {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-43px, -15px);
        -webkit-transform: translate(-43px, -15px);
        -moz-transform: translate(-43px, -15px);
        -ms-transform: translate(-43px, -15px);
        -o-transform: translate(-43px, -15px);

        .redo__btn,
        .del__btn {
          font-size: 25px;
          margin: 0 10px;
          color: #29a0e3;
          text-shadow: 0 0 5px #29a0e3;
          background-color: transparent;
        }

        .del__btn {
          color: #ff5252;
          text-shadow: 0 0 5px #ff5252;
        }
      }
    }
  }
}
