@media (min-width: 1400px) and (max-width: 1600px) {
  .title {
    margin: 0 0 15px !important;
    font-weight: 500 !important;
    font-size: 17px !important;
  }
  .div {
    p {
      font-size: 13px !important;
      font-weight: 500 !important;
    }

    & p:first-child{
      width: '50%';
    }
  }
}
@media (min-width: 1601px) and (max-width: 2160px) {
  .title {
    margin: 0 0 15px !important;
    font-weight: 500 !important;
    font-size: 25px !important;
  }
  .div {
    p {
      font-size: 19px !important;
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
}

.title {
  margin: 0 0 17px;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: #07a287;
}

.div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #232323;
  font-weight: 500;
  padding: 10px 0;
  border-bottom: 1px solid #ddebff;
  p {
    font-size: 14px;
  }
}

.edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  font-size: 16px;

  p {
    color: #b0b7ce;
  }
}
