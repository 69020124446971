.btn {
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 10px;
  color: #ff3b3b;
  background: rgba(255, 59, 59, 0.05);
}

.color {
  color: #fff;
  background: #07a287;
}

.img__card {
  width: 35%;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px 25px;
  margin-right: 25px;

  h5 {
    width: 100%;
    color: #07a287;
    font-weight: 500;
  }

  .img {
    width: 40%;
    margin: 25px auto 35px;
  }
}

.form__card {
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px 25px;

  .card {
    display: flex;
    align-items: center;

    .form {
      width: 60%;
      padding: 20px;

      .label {
        display: block;
        color: #232323;
        margin-bottom: 2px;
      }

      .input {
        width: 100%;
        display: block;
        padding: 6px 8px;
        outline: none;
        border: none;
        background: rgba(51, 51, 51, 0.05);
        border-radius: 8px;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 20px;
        }
      }
    }
  }
}
