.type {
  padding: 5px 15px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  background: rgba(7, 162, 135, 0.1);
  color: #07a287;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;

  a {
    color: #07a287;
  }

  &:hover {
    opacity: 0.7;
  }
}