//@media (min-width: 1400px) and (max-width: 1600px) {
//  .table__main {
//    font-size: 16px !important;
//  }
//  .content__head {
//    .title {
//      font-size: 20px;
//    }
//  }
//}
//
//@media (min-width: 1601px) and (max-width: 2160px) {
//  .table__main {
//    font-size: 19px !important;
//  }
//
//  .content__head {
//    .title {
//      font-size: 23px;
//    }
//  }
//}

.table {
	width: 100%;
	overflow-x: scroll;
	min-height: 100px;

	.noData {
		text-align: center !important;
		margin-top: 20px;
		color: #b0b7ce;
	}

	&__main {
		width: 100%;
		font-size: 14px;

		thead {
			tr {
				td {
					color: #b0b7ce;
					padding: 10px 15px 0;
					white-space: nowrap;
					font-weight: 500;

					&:first-child {
						padding: 10px 0 0;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 15px;
					word-wrap: break-word;
					border-bottom: 1px solid #ddebff;
					font-weight: 500;

					&:first-child {
						padding: 15px 0;
						//padding: 0;
					}
				}

				//&::after, &::before {
				//  content: '';
				//  width: 20px;
				//  height: 100%;
				//  background-color: #E7F6F3;
				//  position: absolute;
				//  z-index: 999;
				//  top: 0;
				//}
				//
				//&::after {
				//  left: 0;
				//  border-radius: 8px 0 0 8px;
				//}
				//
				//&::before {
				//  right: -20px;
				//  border-radius: 0 8px 8px 0;
				//}
				//
				//&:hover {
				//  background-color: #E7F6F3;
				//}
			}
		}
	}
	scrollbar-width: thin;
	scrollbar-color: #f8f9fa rgb(241, 239, 239);
}

.content__head {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.title {
		width: 80%;
		line-height: 22px;
		margin: 0;
		color: #07a287;
	}

	.btns {
		display: flex;
		align-items: center;

		.export__btn {
			display: flex;
			align-items: center;
			padding: 7px 10px;
			border-radius: 8px;
			background-color: #fff;
			border: 0;
			color: #29a0e3;
			transition: all 0.2s ease;
			-webkit-transition: all 0.2s ease;
			-moz-transition: all 0.2s ease;
			-ms-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			-ms-border-radius: 8px;
			-o-border-radius: 8px;

			&:hover {
				background-color: rgba(104, 104, 104, 0.1);
			}
		}

		.add__btn {
			display: flex;
			align-items: center;
			padding: 7px 10px;
			border: 0;
			border-radius: 8px;
			margin-left: 10px;
			color: #07a287;
			background-color: rgba(7, 162, 136, 0.1);
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			-ms-border-radius: 8px;
			-o-border-radius: 8px;
			transition: all 0.3s ease;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			font-family: 'IBM Plex Sans', sans-serif !important;
			font-size: 16px;
			font-weight: 600;

			.icon {
				margin-right: 6px;
				font-size: 25px;
				line-height: 18px;
			}

			&:hover {
				opacity: 0.7;
			}
		}
	}
}

.eye__btn,
.edit__btn,
.del__btn,
.add_btn {
	margin: 0 4px;
	padding: 6px;
	display: flex;
	align-items: center;
	white-space: nowrap;
	background-color: rgba(41, 159, 227, 0.1);
	color: rgb(41, 159, 227);
	border: 0;
	border-radius: 6px;
	white-space: nowrap;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;

	svg {
		width: 20px;
		height: 20px;
	}

	&:hover {
		opacity: 0.7;
	}
}

.add_btn {
	background-color: #07A287;
}

.del__btn {
	background-color: rgba(255, 82, 82, 0.1);
}

.save__btn,
.close__btn,
.delete__btn,
.filter__btn {
	padding: 10px 14px;
	white-space: nowrap;
	background-color: #07a287;
	color: #fff;
	border-radius: 8px;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	display: flex;
	align-items: center;

	&:not(:disabled):hover {
		opacity: 0.7;
	}

	&:disabled {
		opacity: .5;
	}
}

.close__btn {
	background-color: #2323231c;
	color: #000;
}

.delete__btn {
	display: flex;
	align-items: center;
	background-color: #ff5252;
}

.err__back {
	background-color: rgba(255, 82, 82, 0.1) !important;
}

.err__mes {
	font-size: 13px;
	color: red;
}

.mark__btn,
.info__btn {
	font-size: 14px;
	color: #07a287;
	padding: 5px 15px;
	background: rgba(7, 162, 135, 0.1);
	border-radius: 50px;
	white-space: nowrap;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	-o-border-radius: 50px;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;

	&:hover {
		opacity: 0.7;
	}
}

.info__btn {
	color: #29a0e3;
	background: rgba(41, 160, 227, 0.1);
}

.filter__btn {
	color: #fff;
	justify-content: center !important;
	background-color: #29a0e3;
}

.primary__btn {
	padding: 6px 12px;
	border-radius: 8px;
	color: #29a0e3;
	background-color: rgba(41, 160, 227, 0.1);
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;

	&:hover {
		opacity: 0.7;
	}
}

.form {
	label {
		width: 100%;
		color: #a7a7a7;

		.lab {
			font-size: 15px;
			margin-bottom: 8px;
			@media (min-width: 1660px) {
				font-size: 16px;
			}
		}
	}

	input,
	textarea,
	select {
		width: 100%;
		padding: 12px;
		background-color: rgba(51, 51, 51, 0.05);
		border-radius: 8px;
		border: 0;

		&::placeholder {
			color: #bcbcbc;
		}
	}

	select {
		color: #495057;
		padding: 6px 8px;

		&:focus-visible {
			outline: none;
		}
	}

	textarea {
		min-height: 180px;
	}
}
