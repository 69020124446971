.loading__big {
	.loading {
		margin: 0 auto;
		width: 100%;
	}
	.lg_l {
		width: 60px;
		height: 60px;
	}

	.loader {
		position: absolute;
		top: auto;
		left: 50%;
		box-sizing: border-box;

		.face {
			position: absolute;
			border: 2px solid transparent;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 50%;
			border-left: 2px solid #46c5ff;
			border-bottom: 2px solid #46c5ff;
			border-top: 2px solid #46c5ff;
			animation: animate 1s linear infinite;
		}
	}
	.lg {
		width: 60px;
		height: 60px;
		margin-left: -30px;
	}

	.md {
		width: 32px;
		height: 32px;
	}

	.sm {
		width: 16px;
		height: 16px;
	}

	.circle {
		transform: rotate(-45deg);

		&::before {
			background-color: #46c5ff;
			box-shadow: 0 0 20px #46c5ff;
		}
	}
}

.loading__small {
	.md_l {
		width: 32px;
		height: 32px;
	}

	.sm_l {
		width: 16px;
		height: 16px;
	}
	.loader {
		.face {
			width: 16px;
			height: 16px;
			border: 2px solid transparent;
			border-radius: 50%;
			border-left: 2px solid #46c5ff;
			border-bottom: 2px solid #46c5ff;
			border-top: 2px solid #46c5ff;
			animation: animate 1s linear infinite;
		}
	}

	.md {
		width: 32px;
		height: 32px;
	}

	.sm {
		width: 16px;
		height: 16px;
	}

	.circle {
		transform: rotate(-45deg);

		&::before {
			background-color: #46c5ff;
			box-shadow: 0 0 20px #46c5ff;
		}
	}
}

@keyframes animate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
