.button {
  color: #fff;
  padding: 6px 8px;
  border: none;
  background: #07a287;
  border-radius: 8px;
  margin-right: 10px;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 600;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
}
