.pagin {
	display: flex;
	background-color: #fff;
	margin: 1vh 0;
	border-radius: 8px;
	justify-content: flex-end;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;

	&__item {
		padding: 5px 10px;
		cursor: pointer;
		

		&__active {
			background-color: #07A287;
			color: #fff;
			border-radius: 4px;

		}

		&:hover {
			opacity: 0.9;
		}
	}
}
