//  @media (min-width: 1400px) and (max-width: 1600px){

// }

.modalTitle {
	margin: 5px 0 0 30px;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	color: #07a287;
}
.modalBody {
	width: 96%;
	margin: 0 auto;
}
.modalLabel {
	color: #a7a7a7;
	font-size: 15px;
}
.close {
	background: rgba(35, 35, 35, 0.05);
	border-radius: 8px;
	border: none;
	padding: 10px 20px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #232323;
}
.save {
	padding: 10px 20px;
	background: #07a287;
	border-radius: 8px;
	border: none;
	color: white;
}
.modalInput {
	width: 500px;
	height: 50px;
	left: 45px;
	top: 99px;
	background: rgba(51, 51, 51, 0.05);
	border-radius: 8px;
	border: none;
	outline-style: none;
	padding-left: 10px;
}
.modalInput1 {
	width: 240px;
	height: 50px;
	background: rgba(51, 51, 51, 0.05);
	border-radius: 8px;
	border: none;
	outline-style: none;
	padding-right: 60px;
}
.modalInput2 {
	width: 240px;
	height: 50px;
	background: rgba(51, 51, 51, 0.05);
	border-radius: 8px;
	border: none;
	outline-style: none;
	padding-right: 60px;
	margin-left: 70px;
}

.box {
	background: #fafbff;
	border: 3px dashed #cddbff;
	box-sizing: border-box;
	border-radius: 8px;
	width: 180px;
	height: 180px;
	margin-left: 160px;
	margin-top: 35px;
	img {
		margin: 30px 70px;
	}
	p {
		color: #29a0e3;
		font-size: 14px;
		text-align: center;
	}
}
.label {
	margin-left: 70px;
	color: #a7a7a7;
	font-size: 15px;
	margin-top: 2px;
}
