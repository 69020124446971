.login {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #f6f6f8;

  .bgColor {
    background-color: #07a287;
    height: 350px;

    .computerIcon {
      position: absolute;
      top: 50px;
      right: 13%;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    padding: 30px 200px;
    height: 100vh;

    h4 {
      color: #fff;
      font-weight: 500;
    }

    .form {
      width: 460px;
      margin: 80px auto;
      padding: 50px 45px 55px;
      background-color: #ffffff;
      box-shadow: 0 4px 10px rgba(82, 78, 78, 0.05);
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #07a287;
        text-align: center;
        margin-bottom: 1rem;
      }

      label {
        margin: 10px 0;
      }
    }

    .copyright {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      padding-bottom: 15px;
      color: #cececf;
    }
  }
}
